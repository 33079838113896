import React, { createContext, useContext, useEffect, useState, useRef } from "react";
const Elevio = React.lazy(() => import("elevio/lib/react"));

interface ElevioContextType {
    elevioLoaded: boolean;
}

const defaultElevioContext: ElevioContextType = {
    elevioLoaded: false,
};

const ElevioContext = createContext<ElevioContextType>(defaultElevioContext);

export const ElevioProvider = ({ children, language }) => {
    const [elevioLoaded, setElevioLoaded] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!elevioLoaded && intervalRef.current === null) {
            const params = new URLSearchParams(location.search);
            const elevioArticleId = params.get("elevioArticleId");
            const elevioCategoryId = params.get("elevioCategoryId");
            const elevioFAQ = params.get("elevioFaq");
            const checkElev = () => {
                if (elevioArticleId && window._elev && typeof window._elev.openArticle === "function") {
                    window._elev.openArticle(elevioArticleId);
                    clearInterval(intervalRef.current!);
                } else if (elevioFAQ && window._elev && typeof window._elev.openHome === "function") {
                    window._elev.openHome();
                    clearInterval(intervalRef.current!);
                } else if (elevioCategoryId && window._elev && typeof window._elev.openCategory === "function") {
                    window._elev.openCategory(elevioCategoryId);
                    clearInterval(intervalRef.current!);
                } else if (window._elev && typeof window._elev.openArticle === "function") {
                    clearInterval(intervalRef.current!);
                }
            };
            intervalRef.current = setInterval(checkElev, 1000);
            setElevioLoaded(true);
            return () => {
                if (intervalRef.current !== null) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            };
        }
    }, [elevioLoaded]);

    console.log("elevio lang", language)

    return (
        <ElevioContext.Provider value={{ elevioLoaded }}>
            {children}
            {elevioLoaded && (
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Elevio
                        accountId="647f114e4e0aa"
                        settings={{
                            autoInitialize: true,
                            auto_open: false,
                        }}
                        language={language === 'eu' ? 'en-gb' : language}
                    />
                </React.Suspense>
            )}
        </ElevioContext.Provider>
    );
};

export const useElevio = () => {
    return useContext(ElevioContext);
};
